
import { Component, Mixins } from "vue-property-decorator";
import PageSection from "@/components/Common/PageSection.vue";
import { Realm } from "../../models";
import RealmMixin, { RealmTags } from "@/mixins/http/RealmMixin";
import { safeAsync } from "@/utils/AsyncUtil";
import { RegisterHttp } from "@/utils/Decorators";
import { currentUserStore } from "@/store/typed";
import RequestData from "@/store/types/RequestData";
import EmptyView from "@/components/Common/EmptyView.vue";

@Component({
  components: {
    PageSection,
    EmptyView,
  },
})
export default class RealmList extends Mixins(RealmMixin) {
  @RegisterHttp(RealmTags.RealmIndex) readonly indexRequest!: RequestData;

  private items: Realm[] = [];

  private error = false;

  private page = 0;

  get columns() {
    return [
      { name: "name", label: "Nome" },
      { name: "code", label: "Codice" },
      { name: "actions", label: "" },
    ];
  }

  get currentUser() {
    return currentUserStore.currentUser;
  }

  mounted() {
    this.load();
  }

  get loading() {
    return this.indexRequest?.loading;
  }

  get meta() {
    return this.indexRequest?.meta;
  }

  goToDetail(id: string) {
    this.$router.push({ name: "realms_show", params: { realmId: id } });
  }

  get filter() {
    return {
      page: "all",
      filter: {},
    };
  }

  async load() {
    const [data, errors] = await safeAsync(
      this.getRealms(this.filter.page, this.filter.filter)
    );

    if (!errors) {
      this.items = data;
    } else {
      this.error = true;
    }
  }

  get isMobile() {
    return this.$mq === "md" || this.$mq === "sm" || this.$mq === "xs";
  }
}
