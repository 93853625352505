
import { Component, Prop, Ref, Mixins } from "vue-property-decorator";
import CompanySelect from "@/components/Company/Select.vue";
import VModal from "@monade/vue-components/src/components/VModal.vue";
import RealmCompanyAdminMixin, {
  RealmCompanyAdminTags,
} from "@/mixins/http/admin/RealmCompanyAdminMixin";
import LoadingButton from "@/components/Common/LoadingButton.vue";
import { Realm, RealmCompany } from "../../models";
import { safeAsync } from "@/utils/AsyncUtil";
import { RegisterHttp } from "@/utils/Decorators";
import { showToast } from "@/utils/Toast";
import RequestData from "@/store/types/RequestData";
@Component({
  components: { LoadingButton, CompanySelect, VModal },
})
export default class RealmCompanies extends Mixins(RealmCompanyAdminMixin) {
  @Ref() readonly modal!: VModal;
  @Prop({ required: true }) readonly companies!: RealmCompany[];
  @Prop({ default: false }) readonly loading!: boolean;
  @Prop({ required: true }) readonly realm!: Realm;
  @Prop() readonly icon!: string;

  @RegisterHttp(RealmCompanyAdminTags.RealmCompanyCreate)
  readonly createRequest!: RequestData;
  @RegisterHttp(RealmCompanyAdminTags.RealmCompanyDestroy)
  readonly destroyrequest!: RequestData;

  private companyId: string | null = null;

  get realmId() {
    return this.realm?.id;
  }

  async destroy(id: string) {
    const [data, error] = await safeAsync(this.destroyRealmCompany(id));
    if (!error) {
      showToast("Azienda rimossa dal lotto");
      this.$emit("destroyed", id);
      this.hide();
    } else {
      showToast("Non è stato possibile rimuovere l'azienda", { type: "error" });
    }
  }

  showModal(): void {
    (this.modal as any).show();
  }

  get hasCompanies() {
    return this.companies?.length;
  }

  hide(): void {
    (this.modal as any).hide();
  }

  async create() {
    const payload = {
      companyId: this.companyId,
      realmId: this.realmId,
    };

    const [data, error] = await safeAsync(this.createRealmCompany(payload));
    if (!error) {
      showToast("Azienda associata al lotto");
      this.$emit("created", data);
      this.hide();
    } else {
      showToast("Non è stato possibile associare l'azienda", { type: "error" });
    }
  }

  companySelect(id: string): void {
    this.companyId = id;
  }

  get excludedIds(): string[] | number[] {
    const alreadySelectedIds = this.companies.map(
      (u: RealmCompany) => u.company.id
    );

    return [...alreadySelectedIds, this.realm.representative?.id];
  }

  get companySelectFilter() {
    return {
      not: this.excludedIds,
    };
  }
}
