
import { Component, Mixins } from "vue-property-decorator";
import RealmMixin, { RealmTags } from "@/mixins/http/RealmMixin";
import PageSection from "@/components/Common/PageSection.vue";
import { safeAsync } from "@/utils/AsyncUtil";
import RealmServices from "@/components/Service/List.vue";
import RequestData from "@/store/types/RequestData";
import { RegisterHttp } from "@/utils/Decorators";
import OrganizationsList from "@/components/Organization/List.vue";
import RealmCompanies from "@/components/Realm/Companies.vue";
import CompanySelect from "@/components/Company/Select.vue";
import Realm from "@/models/Realm";
import { showToast, showErrorToast } from "@/utils/Toast";
import { RealmCompany } from "../../models";
const tag = RealmTags.RealmShow;

@Component({
  components: {
    OrganizationsList,
    RealmServices,
    PageSection,
    CompanySelect,
    RealmCompanies,
  },
})
export default class RealmShow extends Mixins(RealmMixin) {
  @RegisterHttp(tag) readonly showRequest!: RequestData;

  private item: Realm = null;
  private error = false;
  mounted() {
    this.load();
  }

  async updateRepresentative(representativeId: string) {
    const payload = { representativeId: representativeId, id: this.item.id };
    const [data, errors] = await safeAsync(this.updateRealm(payload));

    if (!errors) {
      this.item.representative = data.representative;
      showToast("Azienda mandataria aggiornata!");
    } else {
      showErrorToast("Si è verificato un errore");
    }
  }

  get representativeId() {
    return this.item?.representative?.id;
  }

  onRealmCompanyAdded(realmCompany: RealmCompany) {
    this.item.companyRealms.push(realmCompany);
  }

  onRealmCompanyDestroyed(id: string) {
    this.item.companyRealms = this.item.companyRealms.filter(
      (o: RealmCompany) => o.id !== id
    );
  }

  get id() {
    return this.$route.params.realmId;
  }

  get loading() {
    return this.showRequest?.loading;
  }

  get meta() {
    return this.showRequest?.meta;
  }

  async load() {
    const [data, errors] = await safeAsync(this.getRealm(this.id));
    if (!errors) {
      this.item = data;
      this.error = false;
    } else {
      this.error = true;
      showErrorToast("Errore nel caricamento della risorsa");
    }
  }
}
