import { httpStore } from "@/store/typed";
import { Component, Vue } from "vue-property-decorator";
import { SD_BASE_ADMIN_API_URL } from "@/utils/Constants";
import { RealmCompany } from "@/models";
import qs from "qs";

export enum RealmCompanyAdminTags {
  RealmCompanyCreate = "RealmCompanyCreate",
  RealmCompanyUpdate = "RealmCompanyUpdate",
  RealmCompanyIndex = "RealmCompanyIndex",
  RealmCompanyDestroy = "RealmCompanyDestroy",
}

@Component
export default class RealmCompanyAdminMixin extends Vue {
  createRealmCompany(data: Partial<RealmCompany>): Promise<RealmCompany> {
    const url = `${SD_BASE_ADMIN_API_URL}/company_realms`;
    return httpStore.request({
      tag: RealmCompanyAdminTags.RealmCompanyCreate,
      url: url,
      method: "POST",
      data: data,
    }) as Promise<RealmCompany>;
  }

  getRealmCompanies(page: number | null | string, filter: any) {
    const url = `${SD_BASE_ADMIN_API_URL}/company_realms`;
    const data = {
      page: page,
      filter: filter,
    };

    return httpStore.request({
      tag: RealmCompanyAdminTags.RealmCompanyIndex,
      url: url,
      method: "GET",
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }) as Promise<RealmCompany[]>;
  }

  updateRealmCompany(
    id: string,
    data: Partial<RealmCompany>
  ): Promise<RealmCompany> {
    const url = `${SD_BASE_ADMIN_API_URL}/company_realms/${id}`;

    return httpStore.request({
      tag: RealmCompanyAdminTags.RealmCompanyUpdate,
      url: url,
      method: "PUT",
      data: data,
    }) as Promise<RealmCompany>;
  }

  destroyRealmCompany(id: string): Promise<RealmCompany> {
    const url = `${SD_BASE_ADMIN_API_URL}/company_realms/${id}`;
    return httpStore.request({
      tag: RealmCompanyAdminTags.RealmCompanyDestroy,
      url: url,
      method: "DELETE",
    }) as Promise<RealmCompany>;
  }
}
