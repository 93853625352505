
import { ServiceAdminTags } from "@/mixins/http/admin/ServiceAdminMixin";
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import VNumericInput, {
  VNumericInputApplication,
} from "@/components/Common/VNumericInput.vue";
import RealmSelect from "@/components/Realm/Select.vue";
import WithErrors from "@/directives/WithErrors";
import Service, { ServicePayload } from "../../models/Service";

@Component({
  directives: {
    WithErrors,
  },
  components: {
    RealmSelect,
    VNumericInput,
  },
})
export default class ServiceForm extends Vue {
  @Prop({ required: true }) readonly value!: ServicePayload;

  private item: ServicePayload = this.value;

  get httpTag() {
    return this.item.id
      ? ServiceAdminTags.ServiceUpdate
      : ServiceAdminTags.ServiceCreate;
  }

  get kinds() {
    return Service.kinds;
  }

  get currencyUsage() {
    return VNumericInputApplication.CURRENCY;
  }

  get realmId() {
    return this.item?.realmId;
  }

  get billingTypes() {
    return Service.billingTypes;
  }

  onBillingTypesChanged(billingType: any, event: Event) {
    const checked = (event as any).checked;
    if (checked) {
      this.item.availableBillingTypes.push(billingType.id);
    } else {
      this.item.availableBillingTypes = this.item.availableBillingTypes.filter(
        (o: any) => o.id !== billingType.id
      );
    }
  }

  @Watch("item", { deep: true })
  onFieldsChanged() {
    this.$emit("input", this.item);
  }
}
