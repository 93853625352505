import { httpStore } from "@/store/typed";
import { Component, Vue } from "vue-property-decorator";
import { SD_BASE_ADMIN_API_URL, SD_BASE_API_URL } from "@/utils/Constants";
import qs from "qs";
import Service from "@/models/Service";

export enum ServiceAdminTags {
  ServiceIndex = "ServiceIndex",
  ServiceCreate = "ServiceCreate",
  ServiceUpdate = "ServiceUpdate",
  ServiceDestroy = "ServiceDestroy",
}

@Component
export default class ServiceAdminMixin extends Vue {
  getServices(page: number | null | string, filter: any) {
    const url = `${SD_BASE_API_URL}/services`;
    const data = {
      page: page,
      filter: filter,
    };

    return httpStore.request({
      tag: ServiceAdminTags.ServiceIndex,
      url: url,
      method: "GET",
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }) as Promise<Service[]>;
  }

  createService(data: any): Promise<Service> {
    const url = `${SD_BASE_ADMIN_API_URL}/services`;

    return httpStore.request({
      tag: ServiceAdminTags.ServiceCreate,
      url: url,
      method: "POST",
      data: data,
    }) as Promise<Service>;
  }

  updateService(data: any): Promise<Service> {
    const url = `${SD_BASE_ADMIN_API_URL}/services/${data.id}`;

    return httpStore.request({
      tag: ServiceAdminTags.ServiceUpdate,
      url: url,
      method: "PUT",
      data: data,
    }) as Promise<Service>;
  }

  destroyService(id: string): Promise<Service> {
    const url = `${SD_BASE_ADMIN_API_URL}/services/${id}`;

    return httpStore.request({
      tag: ServiceAdminTags.ServiceDestroy,
      url: url,
      method: "DELETE",
    }) as Promise<Service>;
  }
}
