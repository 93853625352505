
import { Component, Ref, Mixins } from "vue-property-decorator";

import VModal from "@monade/vue-components/src/components/VModal.vue";
import ServiceAdminMixin, {
  ServiceAdminTags,
} from "@/mixins/http/admin/ServiceAdminMixin";
import { safeAsync } from "@/utils/AsyncUtil";
import { showErrorToast, showToast } from "@/utils/Toast";
import { Service } from "../../models";
import ServiceForm from "./Form.vue";
import { httpStore } from "../../store/typed";
import { ServicePayload } from "../../models/Service";

@Component({
  components: {
    VModal,
    ServiceForm,
  },
})
export default class ServiceModal extends Mixins(ServiceAdminMixin) {
  @Ref() readonly modal!: VModal;

  private service: Service | null = null;

  private servicePayload: ServicePayload | null = null;

  get actionName() {
    return this.isUpdating ? "Aggiorna" : "Crea";
  }

  private onSubmit() {
    if (this.service?.id) {
      this.update();
    } else {
      this.create();
    }
  }

  get isUpdating() {
    return this.service?.id;
  }

  get loading() {
    return (
      httpStore.status[ServiceAdminTags.ServiceCreate]?.loading ||
      httpStore.status[ServiceAdminTags.ServiceUpdate]?.loading
    );
  }

  private async create() {
    const [data, errors] = await safeAsync(
      this.createService(this.servicePayload)
    );

    if (errors) {
      showErrorToast("Errore durante la creazione della linea servizio");
      return;
    }

    showToast("Creazione avvenuta con successo!");
    this.$emit("created", data);
    this.hide();
  }

  get isMobile() {
    return this.$mq === "md" || this.$mq === "sm" || this.$mq === "xs";
  }

  private async destroy() {
    const [data, errors] = await safeAsync(
      this.destroyService(this.service.id)
    );

    if (errors) {
      showErrorToast("Errore durante la cancellazione della linea di servizio");
      return;
    }

    this.$emit("destroyed", data.id);
    this.hide();
  }

  reset() {
    this.service = null;
    this.servicePayload = null;
  }

  private async update() {
    const [data, errors] = await safeAsync(
      this.updateService(this.servicePayload)
    );

    if (errors) {
      showErrorToast("Errore durante la modifica della linea di servizio");
      return;
    }

    showToast("Aggiornamento avvenuto con successo!");
    this.$emit("updated", data);
    this.hide();
  }

  add(service: Service) {
    this.assignObject(service);
    this.modal.show();
  }

  assignObject(service: Service) {
    this.service = service;
    if (this.service) {
      this.servicePayload = new ServicePayload(service);
    }
  }

  edit(service: Service) {
    this.assignObject(service);
    this.modal.show();
  }

  hide() {
    this.modal.hide();
  }
}
